import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }

  .toc {
    border: 1px solid #ababab;
    padding: 20px 40px;
    margin: 0 auto 30px;

    ol {
      padding-left: 30px;
    }

    p {
      text-align: center;
      padding-bottom: 10px;
    }

    a {
      text-decoration: underline;
    }

    li {
      list-style-type: decimal;
      display: list-item;
    }

    ul {
      padding-left: 30px;

      li {
        list-style-type: circle;
      }
    }
  }

  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0012 = () => (
  <Wrapper>
    <ColumnH1 label="年次有給休暇の付与に必要な出勤率は8割？「出勤率」って何？" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      <img src="/images/column/details/c0012.jpg" alt="キンクラコラム" />
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        有給休暇を付与するにあたって法律上避けて通ることが出来ない部分として
        <span>「出勤率」</span>
        の問題があります。これは端的には労働者がどれだけ会社に対して労働力を提供していたかを数値化するということです。
        <br />
        <br />
        この出勤率が一定以下の場合、有給休暇が発生しないケースもあることから、有給休暇における出勤率の算定について解説してまいります。
      </p>
      <div className="toc">
        <p>目次</p>
        <ol>
          <li>
            <a href="#toc1">有給休暇の発生要件</a>
          </li>
          <li>
            <a href="#toc2">法的に出勤とみなすべき日とは</a>
            <ul>
              <li>
                <a href="#toc2-1">
                  業務上負傷または疾病により療養のための休業期間
                </a>
              </li>
              <li>
                <a href="#toc2-2">産前産後休業により休業した期間</a>
              </li>
              <li>
                <a href="#toc2-3">
                  育児介護休業法により育児休業または介護休業した期間
                </a>
              </li>
              <li>
                <a href="#toc2-4">有給休暇を取得した日</a>
              </li>
              <li>
                <a href="#toc2-5">
                  労働者が会社側から正当な理由なく就労を拒まれたために就労することができなかった日
                </a>
              </li>
            </ul>
          </li>
          <li>
            <a href="#toc3">出勤率を基礎とする背景</a>
          </li>
          <li>
            <a href="#toc4">最後に</a>
          </li>
        </ol>
      </div>
      <div id="toc1">
        <h1>有給休暇の発生要件</h1>
      </div>
      <p>
        採用した日から起算して6か月間継続勤務し、全労働日（後述）の8割以上出勤した場合、継続または分割した10日の有給休暇が付与されます。全労働日とは労働契約上、労働義務を課せられている日で一般的には年間のカレンダーから休日を除いた日とされています。
        <br />
        <br />
        尚、全労働日には休日労働した日、不可抗力による休業日、会社側に起因する経営・管理上の障害による休業日、正当な同盟罷業（ストライキ）その他正当な争議行為による労務の提供が全くなされなかった日は除くとされています。
      </p>
      <div id="toc2">
        <h1>法的に出勤とみなすべき日とは</h1>
      </div>
      <p>
        通常、<span>会社で働いた日は名実ともに出勤した日</span>
        となります。しかし、何らかの理由で労働の提供はできなかったがやむを得ないと認められる事由に関しては出勤したものとみなす取り扱いがなされています。
      </p>
      <div id="toc2-1">
        <h2>業務上負傷または疾病により療養のための休業期間</h2>
      </div>
      <p>
        いわゆる<span>業務災害により働けなくなった期間</span>
        のことを指しています。業務災害となると事案や程度にもよりますが会社としての過失も認めざるを得ず、全面的に労働者に責任の所在があると考えるのはさすがに無理があります。よって、これらの日は出勤したものとみなすとされています。
        <br />
        しかし、<span>「通勤災害」</span>
        は対象とされていません。通勤とは労務の提供の為に自宅からの出勤途中の段階であり、会社の目を離れたところでの事故となります。よって、業務災害も通勤災害も労災保険からの給付の対象とはされていますが、出勤率の算定は業務災害に限ってみなすという取り扱いです。
      </p>
      <div id="toc2-2">
        <h2>産前産後休業により休業した期間</h2>
      </div>
      <p>
        母体保護の観点からも
        <span>
          出産予定日から起算して6週間は産前休業、出産後8週間は産後休業として就業が禁止
        </span>
        されています（産前は労働者から請求があった場合で産後の6週間は例外なく強制就業禁止期間）。よって、これらの期間は欠勤と同じ扱いとする取り扱いは馴染まず、出勤したものとみなす取り扱いとなります。
        <br />
        <br />
        また、分娩が遅れることは決して珍しいことではありません。その場合、結果的に産前6週間を超えた期間の当該休業期間分も出勤率の算定にあたっては出勤したものとみなして取り扱わなければなりません。
        <br />
        <br />
        尚、類似の論点として、生理のために就業が著しく困難となった女性労働者が（有給休暇ではなく生理）休暇を請求した場合、法律上は出勤率の算定に当たり出勤したものとみなすという規定はありませんが
        <span>当事者間の合意の元</span>
        、出勤したものとみなす取り扱いは可能です。
      </p>
      <div id="toc2-3">
        <h2>育児介護休業法により育児休業または介護休業した期間</h2>
      </div>
      <p>
        産前産後休業と同様に子の養育や家族の介護のためにそれぞれ育児休業、介護休業した期間は出勤したものとみなす取り扱いです。
      </p>
      <Introduction003 />
      <div id="toc2-4">
        <h2>有給休暇を取得した日</h2>
      </div>
      <p>
        現実的には労務の提供がなされた日ではありませんが、
        <span>
          労働法制上、唯一休暇でありながら賃金支払い義務が課せられる日が有給休暇
        </span>
        です。万が一、有給休暇を取得することで出勤率が下がるという構造であれば、当然、有給休暇の取得が進まないといった事態にも発展しかねないことからこのような取扱いとなっています。
      </p>
      <div id="toc2-5">
        <h2>
          労働者が会社側から正当な理由なく就労を拒まれたために就労することができなかった日
        </h2>
      </div>
      <p>
        代表的な例としては無効な解雇により会社側から正当な理由なく就労を拒まれ、労働者が就労することができなかった日があります。また、その拒んだ内容が労働者の責に帰すべき事由とは言えない場合、単なる不就労日とは言えません。
        <br />
        このような日は当事者間の衡平等の観点から出勤率の算定に当たっては出勤日数に含めるべき日として扱うことが判例上も示されています（八千代交通事件
        平成25年6月6日最高裁）。
      </p>
      <div id="toc3">
        <h1>出勤率を基礎とする背景</h1>
      </div>
      <p>
        有給休暇の請求権の発生について出勤率8割を求める背景には労働者の勤怠状況を把握し、特に出勤率の低い労働者を除外する立法趣旨であるとされています。
        <br />
        尚、全労働日については会社ごとに異なることはもちろん、会社内の職種によっても異なることがあり得ます。よって、就業規則に明確に規定しておくことがトラブル防止の観点からも重要です。また、繁忙期に差し掛かり、所定の休日に労働させたとしてもその日は当初は働くべき日ではなかったことから、全労働日には含めません。
      </p>
      <div id="toc4">
        <h1 className="read">最後に</h1>
      </div>
      <p>
        有給休暇の出勤率はいわば<span>最低基準</span>
        であり、8割を下回った場合でも労使間の取り決めにより有給休暇を付与すること自体、問題はありません。特に入社後間もない時期に家庭の事情で休まざるを得ないと言った場合も想定されます。
        <br />
        法律上の基準はあくまで最低基準となりますが、明らかに画一的な取扱いが馴染まないと言った特殊な場合や、まずは法律上と同等の水準で運用していくといった場合にはおさえておく必要のある論点です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0012
