import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0012 from '../../../components/molecules/columnDetailInner0012'
import UsefulFunctionLayout011 from '../../../components/molecules/usefulFunctionLayout011'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0012 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '有給休暇の算出に必要！出勤率について詳しく解説',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="年次有給休暇の付与に必要な出勤率は8割？「出勤率」って何？"
        description="年次有給休暇は採用した日から起算して6か月間継続勤務し、全労働日の8割以上出勤した場合に付与されます。有給や産休などは「出勤」として判断されるのか否か、解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0012/"
        ogType="article"
        ogTitle="年次有給休暇の付与に必要な出勤率は8割？「出勤率」って何？"
        ogDescription="年次有給休暇は採用した日から起算して6か月間継続勤務し、全労働日の8割以上出勤した場合に付与されます。有給や産休などは「出勤」として判断されるのか否か、解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0012.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0012 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout011 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0012

export const pageQuery = graphql`
  query C0012 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
